import React from 'react';
import Layout from '../layout/layout';
import SEO from '../components/SEO';
import BlogDetails from '../components/BlogDetails';
import { Footnotes } from '../layout/global-style';

const bloglayout = () => {
  return (
    <Layout pageTitle="Terms of Services">
      <SEO
        title="Optimum Futurist"
        lang="en"
        description="Terms of Service of Optimum Futurist"
      />
      <BlogDetails
        img="/img/text-page-header.webp"
        title="Terms of Services"
        tag="Legal"
        postedOn="5 March 2019"
      >
        <h3>1. Terms</h3>
        <p>
          By accessing the website at{' '}
          <a href="https://www.optimumfuturist.com?utm_source=Website&amp;utm_medium=TermsOfServices">
            https://optimumfuturist.com
          </a>
          , you are agreeing to be bound by these terms of service, all
          applicable laws and regulations, and agree that you are responsible
          for compliance with any applicable local laws. If you do not agree
          with any of these terms, you are prohibited from using or accessing
          this site. The materials contained in this website are protected by
          applicable copyright and trademark law.
        </p>
        <h3>2. Use License</h3>
        <ol type="a">
          <li>
            Permission is granted to temporarily download one copy of the
            materials (information or software) on Optimum Futurist Pvt.
            Ltd.&apos;s website for personal, non-commercial transitory viewing
            only. This is the grant of a license, not a transfer of title, and
            under this license you may not:
            <ol type="i">
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial);
              </li>
              <li>
                attempt to decompile or reverse engineer any software contained
                on Optimum Futurist Pvt. Ltd.&apos;s website;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transfer the materials to another person or &apos;mirror&apos;
                the materials on any other server.
              </li>
            </ol>
          </li>
          <li>
            This license shall automatically terminate if you violate any of
            these restrictions and may be terminated by Optimum Futurist Pvt.
            Ltd. at any time. Upon terminating your viewing of these materials
            or upon the termination of this license, you must destroy any
            downloaded materials in your possession whether in electronic or
            printed format.
          </li>
        </ol>
        <h3>3. Disclaimer</h3>
        <ol type="a">
          <li>
            The materials on Optimum Futurist Pvt. Ltd.&apos;s website are
            provided on an &apos;as is&apos; basis. Optimum Futurist Pvt. Ltd.
            makes no warranties, expressed or implied, and hereby disclaims and
            negates all other warranties including, without limitation, implied
            warranties or conditions of merchantability, fitness for a
            particular purpose, or non-infringement of intellectual property or
            other violation of rights.
          </li>
          <li>
            Further, Optimum Futurist Pvt. Ltd. does not warrant or make any
            representations concerning the accuracy, likely results, or
            reliability of the use of the materials on its website or otherwise
            relating to such materials or on any sites linked to this site.
          </li>
        </ol>
        <h3>4. Limitations</h3>
        <p>
          In no event shall Optimum Futurist Pvt. Ltd. or its suppliers be
          liable for any damages (including, without limitation, damages for
          loss of data or profit, or due to business interruption) arising out
          of the use or inability to use the materials on Optimum Futurist Pvt.
          Ltd.&apos;s website, even if Optimum Futurist Pvt. Ltd. or a Optimum
          Futurist Pvt. Ltd. authorized representative has been notified orally
          or in writing of the possibility of such damage. Because some
          jurisdictions do not allow limitations on implied warranties, or
          limitations of liability for consequential or incidental damages,
          these limitations may not apply to you.
        </p>
        <h3>5. Accuracy of materials</h3>
        <p>
          The materials appearing on Optimum Futurist Pvt. Ltd.&apos;s website
          could include technical, typographical, or photographic errors.
          Optimum Futurist Pvt. Ltd. does not warrant that any of the materials
          on its website are accurate, complete or current. Optimum Futurist
          Pvt. Ltd. may make changes to the materials contained on its website
          at any time without notice. However Optimum Futurist Pvt. Ltd. does
          not make any commitment to update the materials.
        </p>
        <h3>6. Links</h3>
        <p>
          Optimum Futurist Pvt. Ltd. has not reviewed all of the sites linked to
          its website and is not responsible for the contents of any such linked
          site. The inclusion of any link does not imply endorsement by Optimum
          Futurist Pvt. Ltd. of the site. Use of any such linked website is at
          the user&apos;s own risk.
        </p>
        <h3>7. Modifications</h3>
        <p>
          Optimum Futurist Pvt. Ltd. may revise these terms of service for its
          website at any time without notice. By using this website you are
          agreeing to be bound by the then current version of these terms of
          service.
        </p>
        <h3>8. Governing Law</h3>
        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of Kathmandu, Nepal and you irrevocably submit to the
          exclusive jurisdiction of the courts in that State or location.
        </p>
        <Footnotes>
          <h5>Footnotes:</h5>
          <p>
            Banner image copyright:{' '}
            <a
              rel="noopener noreferrer nofollow"
              href="https://unsplash.com/@florianolv?utm_source=OptimumFuturist&utm_medium=referral&utm_content=creditCopyText"
            >
              Florian Olivo
            </a>{' '}
            on{' '}
            <a
              rel="noopener noreferrer nofollow"
              href="https://unsplash.com/?utm_source=OptimumFuturist&utm_medium=referral&utm_content=creditCopyText"
            >
              Unsplash
            </a>
          </p>
        </Footnotes>
      </BlogDetails>
    </Layout>
  );
};

export default bloglayout;
